import $ from 'jquery';
window.jQuery = $;
window.$ = $;

jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchstart", handle, {
      passive: !ns.includes("noPreventDefault")
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchmove", handle, {
      passive: !ns.includes("noPreventDefault")
    });
  }
};
jQuery.event.special.wheel = {
  setup: function (_, ns, handle) {
    this.addEventListener("wheel", handle, {
      passive: true
    });
  }
};
jQuery.event.special.mousewheel = {
  setup: function (_, ns, handle) {
    this.addEventListener("mousewheel", handle, {
      passive: true
    });
  }
};

// import './js/navigation.js'; 
// import Foundation from 'foundation-sites';


// import { Foundation } from './theme-critical'; // currently not using

var WebFont = require('webfontloader'); // Page base

import {
  Foundation
} from 'foundation-sites/js/foundation.core';
// import * as CoreUtils from 'foundation-sites/js/foundation.core.utils'; // not using - needed for utils ??????
import { Box } from 'foundation-sites/js/foundation.util.box'; // Will return an object that contains the dimensions of element
// import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader'; // This will execute your callback function after all the images in your jQuery collection have loaded.
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard'; // Methods for keyboard interaction 
// import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery'; // The media query library used by Foundation has two publicly accessible functions and two properties
// import { Motion, Move } from 'foundation-sites/js/foundation.util.motion'; // Two utils
import { Nest } from 'foundation-sites/js/foundation.util.nest';
// import { Timer } from 'foundation-sites/js/foundation.util.timer'; // Similar to setInterval, except you can pause and resume where you left off
import { Touch } from 'foundation-sites/js/foundation.util.touch'; // Allows you to add swipe* and pseudo-drag events to elements
// import { Triggers } from 'foundation-sites/js/foundation.util.triggers'; // Provides a number of event listeners and triggers your script can hook into
// import { Abide } from 'foundation-sites/js/foundation.abide';
// import { Accordion } from 'foundation-sites/js/foundation.accordion';
import {
  AccordionMenu
} from 'foundation-sites/js/foundation.accordionMenu'; // Page base
// import { Drilldown } from 'foundation-sites/js/foundation.drilldown'; // Page base
// import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
import {
  DropdownMenu
} from 'foundation-sites/js/foundation.dropdownMenu'; // Page base
// import { Equalizer } from 'foundation-sites/js/foundation.equalizer';
// import { Interchange } from 'foundation-sites/js/foundation.interchange';
// import { Magellan } from 'foundation-sites/js/foundation.magellan';
import {
  OffCanvas
} from 'foundation-sites/js/foundation.offcanvas'; // Page base
// import { Orbit } from 'foundation-sites/js/foundation.orbit';
import {
  ResponsiveMenu
} from 'foundation-sites/js/foundation.responsiveMenu'; // Page base
// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle'; // _jwp_nav-title-bar.php
// import { Reveal } from 'foundation-sites/js/foundation.reveal';
// import { Slider } from 'foundation-sites/js/foundation.slider';
// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
// import { Sticky } from 'foundation-sites/js/foundation.sticky';
// import { Tabs } from 'foundation-sites/js/foundation.tabs';
// import { Toggler } from 'foundation-sites/js/foundation.toggler';
// import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';

Foundation.addToJquery($);
// test adding a foundation component to jQuery. 

// Add Foundation Utils to Foundation global namespace for backwards
// compatibility.

// *** Not using - needed for utils ??????
// Foundation.rtl = CoreUtils.rtl;
// Foundation.GetYoDigits = CoreUtils.GetYoDigits;
// Foundation.transitionend = CoreUtils.transitionend;
// Foundation.RegExpEscape = CoreUtils.RegExpEscape;
// Foundation.onLoad = CoreUtils.onLoad;

// *** Not using - needed for utils ??????
Foundation.Box = Box;
// Foundation.onImagesLoaded = onImagesLoaded;
Foundation.Keyboard = Keyboard;
// Foundation.MediaQuery = MediaQuery;
// Foundation.Motion = Motion;
// Foundation.Move = Move;
Foundation.Nest = Nest;
// Foundation.Timer = Timer;

// Touch and Triggers previously were almost purely sede effect driven,
// so no need to add it to Foundation, just init them.

// *** Not using - needed for utils ??????
Touch.init($);
// Triggers.init($, Foundation);
// MediaQuery._init();

// Foundation.plugin(Abide, 'Abide');
// Foundation.plugin(Accordion, 'Accordion');
Foundation.plugin(AccordionMenu, 'AccordionMenu'); // Page base
// Foundation.plugin(Drilldown, 'Drilldown'); // Page base
// Foundation.plugin(Dropdown, 'Dropdown');
Foundation.plugin(DropdownMenu, 'DropdownMenu'); // Page base
// Foundation.plugin(Equalizer, 'Equalizer');
// Foundation.plugin(Interchange, 'Interchange');
// Foundation.plugin(Magellan, 'Magellan');
Foundation.plugin(OffCanvas, 'OffCanvas'); // Page base
// Foundation.plugin(Orbit, 'Orbit');
Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu'); // Page base
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
// Foundation.plugin(Reveal, 'Reveal');
// Foundation.plugin(Slider, 'Slider');
// Foundation.plugin(SmoothScroll, 'SmoothScroll');
// Foundation.plugin(Sticky, 'Sticky');
// Foundation.plugin(Tabs, 'Tabs');
// Foundation.plugin(Toggler, 'Toggler');
// Foundation.plugin(Tooltip, 'Tooltip');
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');

// export { Foundation };


// import {
//     initializer
// } from './js/foundation';
// require('waypoints/lib/noframework.waypoints.min');
import 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick.css';
// import './styles/critical.scss';
import './theme.scss';

// import AOS from 'aos';
// import 'aos/dist/aos.css'; // You can also use <link> for styles
import { SlickSlider } from './js/js-parts/js-slick-slider.js';

// initializer.key_initFoundation();
// jQuery(document).foundation();
// cannot apply update. Need to do a full reload - only accept if full relod is not needed
// if(module && module.hot){
//     module.hot.accept();
// }   
// ****************************** // 
// ****** WRAPPER  ****** // 
// ****************************** // 
// IIFE - Immediately Invoked Function Expression
(function (bptcode) {

  // The global jQuery object is passed as a parameter 
  bptcode(window.jQuery, window, document);

}(function ($, window, document) {

  // The $ is now locally scoped  
  $(document).ready(function () {

    // *** CODE HERE *** //

    $(document).foundation();

    WebFont.load({
      custom: {
        families: ['Lora', 'Noto Sans'],
      }
    });

    const SlickSliderHHR = new SlickSlider({
      selector: '.hero-home__repeater',
      args: {
          speed: 300,
          arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 5000,
          pauseOnFocus: false,
          pauseOnHover: false,
          mobileFirst: true,
          infinite: true,
          adaptiveHeight: false,
          fade: true,
          cssEase: 'linear',
          responsive: [{
              breakpoint: 1,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }]
      }
    });
    SlickSliderHHR.SlickSliderInit();

    $('.accordion-menu').removeAttr('role');
    $('.accordion-menu li').removeAttr('role');
    $('.accordion-menu li a').removeAttr('role');
    $('.accordion-menu li a').removeAttr('aria-current');
    $('.accordion-menu').removeAttr('aria-multiselectable');

    function arrowButtons() {
      let arrowButtons = $('.button.arrow');
      if (arrowButtons.length > 0) {
        arrowButtons.each(function () {
          $(this).append(`<svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="13.377" viewBox="0 0 16.5 13.377">
            <g id="Group_5054" data-name="Group 5054" transform="translate(-183 -3948.812)">
              <line id="Line_173" data-name="Line 173" x2="13.5" transform="translate(184 3955.5)" fill="none" stroke="#ffce1a" stroke-linecap="round" stroke-width="2"/>
              <path id="Path_10587" data-name="Path 10587" d="M-11303.662-2888.175l5.274,5.274-5.274,5.274" transform="translate(11496.888 6838.4)" fill="none" stroke="#ffce1a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </svg>`);
        });
      }
    }
    arrowButtons();

    function downloadButtons() {
      let arrowButtons = $('.button.download');
      if (arrowButtons.length > 0) {
        arrowButtons.each(function () {
          $(this).append(`<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.5" cy="14.4082" r="14" fill="#EC2226"/>
            <path d="M21.4829 13.8003C20.2858 14.9974 16.3289 18.9544 14.5 20.7832M14.5 20.7832L7.51714 13.8003M14.5 20.7832L14.5 8.0332" stroke="white" stroke-width="1.5"/>
          </svg>`);
        });
      }
    }
    downloadButtons();

    function linkButtons() {
      let arrowButtons = $('.button.link');
      if (arrowButtons.length > 0) {
        arrowButtons.each(function () {
          $(this).append(`<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
            <g id="Group_5097" data-name="Group 5097" transform="translate(-506 -9418)">
              <circle id="Ellipse_1182" data-name="Ellipse 1182" cx="19" cy="19" r="19" transform="translate(506 9418)" fill="#ffce1a"/>
              <g id="Group_5081" data-name="Group 5081" transform="translate(12376.484 10903.322)">
                <path id="Path_10600" data-name="Path 10600" d="M20.438,35.587A8.092,8.092,0,0,0,28.521,27.5a.948.948,0,1,0-1.9,0,6.187,6.187,0,1,1-6.187-6.187.948.948,0,0,0,0-1.9,8.083,8.083,0,0,0,0,16.167Z" transform="translate(-11873 -1492.745)" fill="#171c47"/>
                <path id="Path_10601" data-name="Path 10601" d="M52.471,12.349l-5.26.154a.948.948,0,1,0,.052,1.9l2.879-.081L43.1,21.346a.952.952,0,0,0,1.346,1.346l7.035-7.04L51.4,18.531a.948.948,0,0,0,.922.974h.026a.948.948,0,0,0,.948-.922l.147-5.26a.948.948,0,0,0-.974-.974Z" transform="translate(-11896.249 -1487.348)" fill="#171c47"/>
              </g>
            </g>
          </svg>`);
        });
      }
    }
    linkButtons();

    $('article table').each(function(){
      $(this).wrap('<div class="table-scroll"></div>')
    });

    $('.no-fouc').removeClass('no-fouc');

    jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
        jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
    });
    
    // *** END CODE *** //
    // ****************************** // 
    // ****** END WRAPPER  ****** // 
    // ****************************** //
  });
}));